.do-not-sell-container {
    background-color: #ebf3f7;
    height: 100%;
    padding: 20px 0;
}

.do-not-sell-container{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 20px;
    height: auto;
}