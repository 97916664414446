.offer_post {
    margin: 20px auto;
    padding: 0px 20px;
}

.offer_post h2 {
    display: flex;
    line-height: 35px;
    font-size: 30px !important;
    text-transform: capitalize !important;
    text-decoration: none;
    color: #000000 !important;
    font-weight: bold;
    letter-spacing: .01em;
    display: block;
}

.offer_post .title__link {
    text-decoration: none;
    color: #000000;
}

.offer_post .title__link:hover {
    text-decoration: underline;
    /* color: #0e22a4; */
}

.offer_image img {
    max-width: 100%;
    min-width: 100%;
    margin: 20px auto;
    width: 100%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 100vw;
}

.unit {
    background-color: #595959;
    color: #ffffff;
    padding: 8px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 5px;
}

a {
    text-decoration: underline;
    color: #207aff;
    font-weight: bold;
    letter-spacing: .01em;
}

.button {
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    border: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    /* background-color: #db2828; */
    background: linear-gradient(160deg,#00FFDC,#26C3F7,#279DD7,#2685F7,#267BF7,#1C77E4,#2269BE,#135CB4);
    padding: 14px 0;
    font-size: 1.71428571rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.button:hover {
    background-color: #f60707;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

 /* BELOW new read more btn and gradient */
.offer_content_container {
  position: relative;
  line-height: 1.5em; /* Set the height of each line of text */
  max-height: 10em; /* Show approximately 4 lines of text when collapsed */
  overflow: hidden; /* Hide overflow for truncated text */
  transition: max-height 0.3s ease-in-out; /* Smooth transition for read more/less */
}
  
  .gradient {
 /* The same height as the container */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%);
    position: relative;
  }
  
  .read-more-btn {
    display: block;
    margin: 0px 0 12px 0;
    padding: 8px 16px;
    background-color: #1b508800;
    color: #909090;
    cursor: pointer;
    width: 200px;
    border-radius: 4px;
    text-align: left;
    font-size: 14px;
    }

    .read-more-btn:hover {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        color: #529aee;
    }

  .gradient::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px; /* Gradient height */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
  }
  
  
@media (max-width: 600px) {
    .offer_image img {
        margin: 0px auto;
        max-width: 100%;
        width: 100%;
    }

    .offer_post h2 {
        font-size: 25px !important;
    }
    
    .offer_content_container {
        max-height: 12.0em; /* Show approximately 3 lines of text on smaller screens */
    }
}
